@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,900;1,300&display=swap');

:root {
	--white: #ffffff;
	--green: #20eb66;
	--red: #ff2525;
	--yellow: #d3d41f;
	--off-white: #e8e8e8;
	--off-white2: #e5e7e6;
	--dark1: #191a1c;
	--dark2: #1b1b1b;
	--label: #797979;
	--dark5: #a9a9a9;
	--dark6: #7e7e7e;
}
body {
	background-image: url('../img/background.png');
}
.main__content {
	background: #000000;
}
.text-green {
	color: var(--green) !important;
}
.text-dark6 {
	color: var(--dark6) !important;
}
.pointer {
	cursor: pointer;
}
*:focus {
	border-color: none !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}
.mt-20 {
	margin-top: 20px;
}
.dashboard__sidebar {
	min-height: 100vh;
	background: var(--dark1);
}
.dashboard__content {
	max-height: 100vh;
	overflow-y: scroll;
	/* background: var(--dark2); */
}

.vertical__nav .dashboard__logo {
	margin-top: 30px;
	padding: 40px 45px;
	margin-bottom: 30px;
}

.vertical__nav .nav-item .nav-link {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	/* identical to box height */
	color: var(--white);
	opacity: 0.7;
	padding: 15px 30px;
	margin: 5px 20px;
}
.vertical__nav .active__link {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	color: var(--white);
	opacity: 1 !important;
	background: rgba(38, 175, 85, 0.16);
	border-radius: 11px;
	padding: 15px 30px;
}
@media (max-width: 767.98px) {
	.vertical__nav .dashboard__logo {
		height: 60px;
		margin-top: 35px;
		padding: 0px 5px;
	}
	.first__nav-item {
		margin-top: 100px;
	}
	.vertical__nav .nav-item .nav-link {
		padding: 12px;
		margin: 5px 5px;
	}
}

/* Dashboard Page */
.font-props {
	font-family: 'Poppins';
	font-style: normal;
}
.web-title {
	font-weight: 600;
	font-size: 30px;
	line-height: 45px;
	color: var(--off-white);
	padding-top: 100px;
}
.section-heading {
	font-weight: 600;
	font-size: 26.6667px;
	line-height: 40px;
	text-align: center;
	color: var(--off-white);
	margin-top: 10px;
}

.custom-card {
	background: var(--dark2);
	border-radius: 25px !important;
	border: 0 !important;
}
.custom-card .card-body {
	padding: 23px 24px !important;
}
.card-label {
	font-weight: 400;
	font-size: 20px;
	line-height: 30px;
	color: var(--label);
}
.number-gradient {
	background-color: #f7f7f7;
	background-image: radial-gradient(
		50.49% 183.68% at 100% 50%,
		#9f9f9f 0%,
		#f7f7f7 100%
	);
	background-size: 100%;
	-moz-background-clip: text;
	-moz-text-fill-color: transparent;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
}
.gem-number {
	font-weight: 500;
	font-size: 70.7964px;
	line-height: 66px;
	letter-spacing: -0.05em;
}
@media (max-width: 991.92px) {
	.gem-number {
		font-size: 48px;
		line-height: 48px;
	}
}
.green-price {
	font-weight: 500;
	font-size: 36px;
	line-height: 36px;
	letter-spacing: -0.05em;
}
.up-percent {
	font-weight: 300;
	font-size: 12px;
	line-height: 18px;
	color: var(--yellow);
}
.down-percent {
	font-weight: 300;
	font-size: 12px;
	line-height: 18px;
	color: var(--red);
}
.gem-img {
	background: url('../img/gem-1.png');
	background-size: cover;
	/* box-shadow: 0px 5.41241px 23.0027px -5.41241px rgba(38, 174, 85, 0.28); */
	width: 66.3px;
	height: 66.3px;
}
.card-tagline {
	font-weight: 300;
	font-size: 11px;
	line-height: 11px;
	color: var(--dark5);
}
.claim-btn-gradient {
	background-image: radial-gradient(
		50.49% 183.68% at 100% 50%,
		#26ae55 0%,
		#20eb66 100%
	) !important;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;

	text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.41) !important;
}
.btn-claim {
	font-weight: 500;
	font-size: 18px;
	line-height: 27px;
	text-align: center;
	letter-spacing: -0.05em;
	filter: drop-shadow(
		0px 9.39394px 25.8333px rgba(38, 174, 85, 0.1)
	) !important;
	border-radius: 13.58px !important;
	border: 2px solid var(--green);
}
.green-btn-gradient {
	background: var(--green) !important;

	text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.41) !important;
}
.btn-buy-green {
	font-weight: 500;
	font-size: 18px;
	line-height: 27px;
	text-align: center;
	letter-spacing: -0.05em;
	filter: drop-shadow(
		0px 9.39394px 25.8333px rgba(38, 174, 85, 0.1)
	) !important;
	border-radius: 13.58px !important;
	border: 2px solid var(--green);
	color: var(--off-white2);
}
.bottom-nodes {
	position: absolute;
	bottom: 0;
	margin-bottom: 10px;
}
.bottom-nodes h2 {
	font-weight: 500;
	font-size: 44.4944px;
	line-height: 45px;
	letter-spacing: -0.05em;
}
.bottom-nodes span {
	font-weight: 300;
	font-size: 20.2632px;
	line-height: 30px;
	letter-spacing: 0.015em;
	color: var(--green);
}
@media (max-width: 991.98px) {
	.bottom-nodes {
		position: relative !important;
	}
}

.create-node-card {
	background: var(--dark2);
	border-radius: 25px !important;
	border: 0 !important;
}
.create-node-card .card-body {
	padding: 57px 85px !important;
}

.green-avax-price {
	font-weight: 600;
	font-size: 36.25px;
	line-height: 44px;
	letter-spacing: -0.05em;
	color: var(--white);
}
@media (max-width: 767.98px) {
	.create-node-card .card-body {
		padding: 28px 20px !important;
	}
	.green-avax-price {
		font-size: 26px;
		line-height: 26px;
	}
}
.btn-approve {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 20.8125px;
	line-height: 31px;
	text-align: center;
	letter-spacing: -0.05em;
	filter: drop-shadow(0px 10.8617px 59px rgba(38, 174, 85, 0.3)) !important;
	border-radius: 13.5772px !important;
	background-image: radial-gradient(
		50.49% 183.68% at 100% 50%,
		#26ae55 0%,
		#20eb66 100%
	) !important;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	border: 2px solid var(--green);
	text-shadow: 0px 2.71544px 13.5772px rgba(0, 0, 0, 0.41);
	padding: 10px !important;
}
.filter__nodes .search-nodes {
	position: relative;
}
.filter__nodes .search-nodes .form-control {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500 !important;
	font-size: 18px !important;
	line-height: 27px !important;
	color: #797979 !important;
	background: transparent !important;
	border-top: 0 !important;
	border-left: 0 !important;
	border-right: 0 !important;
	border-radius: 0 !important;
	border-bottom: 2px solid var(--green) !important;
	padding-right: 30px;
}
.filter__nodes .search-nodes i {
	position: absolute;
	right: 0;
	top: 10px;
	color: var(--green) !important;
	font-size: 18px;
}
.filter__nodes .filter-nodes .form-control {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500 !important;
	font-size: 18px !important;
	line-height: 27px !important;
	color: #ffffff !important;
	background: transparent !important;
	border-top: 0 !important;
	border-left: 0 !important;
	border-right: 0 !important;
	border-radius: 0 !important;
	border-bottom: 2px solid var(--green) !important;
}
.table-responsive {
	max-height: 540px !important;
}
.table-header th {
	background: rgba(38, 175, 85, 0.16) !important;
	border: 0 !important;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500 !important;
	font-size: 14px !important;
	line-height: 21px !important;
	color: #797979 !important;
	padding: 22px 10px !important;
}
.table-header th:first-child {
	border-top-left-radius: 25px !important;
}
.table-header th:last-child {
	border-top-right-radius: 25px !important;
}
.custom-table-body {
	background: var(--dark2) !important;
}
.custom-table-body td {
	border: 0 !important;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500 !important;
	font-size: 16px !important;
	line-height: 24px !important;
	color: #ffffff !important;
}

.restore-btn-gradient {
	background-image: radial-gradient(
		50.49% 183.68% at 100% 50%,
		#9f9f9f 0%,
		#f7f7f7 100%
	) !important;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;

	text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.41) !important;
}
.btn-restore {
	font-weight: 500;
	font-size: 18px;
	line-height: 18px;
	text-align: center;
	letter-spacing: -0.05em;
	filter: drop-shadow(
		0px 9.39394px 25.8333px rgba(38, 174, 85, 0.1)
	) !important;
	border-radius: 13.58px !important;
	border: 2px solid var(--white);
}

button.btn-connect.btn-buy-green.green-btn-gradient {
    height: 43px;
	line-height: 27px;
    margin-top: 100px;
}

input.buy_input.form-control {
    background: #fff2f2;
    border: none;
    margin-bottom: 8px;
    border-radius: 8px;
}

.nodata-owned {
    color: #e9e9e9;
    margin-top: 24px;
    text-align: center;
}